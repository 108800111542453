import { commaizeNumber, decommaizeNumber } from "@toss/utils";
import { CountryCode, isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import qs from "qs";
import { useMemo } from "react";

/* 
  number 를 받아서 천단위 콤마를 찍어서 string 으로 리턴
  ex) numberToStringWithComma(1000) => "1,000"
  ex) numberToStringWithComma(1000.34) => "1,000"
  ex) numberToStringWithComma(1000.34, "0,0.00") => "1,000.34"
  ex) numberToStringWithComma(1000.34, "0,0.0") => "1,000.3"
*/
function numberToStringWithComma(num?: number, format: string = "0,0"): string {
  // return (num || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return numeral(num).format(format);
}

/* 
  소수점 두자리까지 반올림
  ex) roundToTwo(45.666) => 45.67
*/
function roundToTwo(num?: number): number {
  const value = num || 0;
  const m = Number((Math.abs(value) * 100).toPrecision(15));
  const round = (Math.round(m) / 100) * Math.sign(value);
  return round;
}

/* 
  소수점 두자리까지 버림
  ex) floorToTwo(45.666) => 45.66
*/
function floorToTwo(num?: number): number {
  const value = num || 0;
  const m = Number((Math.abs(value) * 100).toPrecision(15));
  const floor = (Math.floor(m) / 100) * Math.sign(value);
  return floor;
}

/* 
  평수 계산 (m2 x 0.3025, 소수점 2자리 반올림). 천단위 콤마 처리
  ex) calculatePyeong(1000000) => "302,500.00"
  ex) calculatePyeong(1000000, "0,0") => "302,500"
 */
function calculatePyeong(num?: number, format: string = "0,0.00"): string {
  const pyeong = (num || 0) * 0.3025;
  return numeral(pyeong).format(format);
}

/* 
  평수 계산 (m2 x 0.3025, 소수점 2자리 반올림)
  ex) calculatePyeongNum(444444) => 134444.31
 */
function calculatePyeongNum(num?: number): number {
  const pyeong = (num || 0) * 0.3025;
  return roundToTwo(pyeong);
}

// 전용률 구하기 - 전용면적 / 계약면적 * 100
function calculateRateOfUse(
  leasableAreaNet: string | number | undefined,
  leasableArea: string | number | undefined,
  format: string = "0,0.00",
): string {
  const value = (Number(leasableAreaNet || 0) / Number(leasableArea || 0)) * 100;
  const floorValue = floorToTwo(value);
  const result = numeral(floorValue).format(format);
  return result;
}

// api용으로 사용합니다.
enum YmdFormat {
  YYYY = "YYYY",
  MM = "MM",
  YYYY_MM = "YYYY-MM",
  YYYY_MM_DD = "YYYY-MM-DD",
  TIME = "HH:mm:ss",
  YYYY_MM_DD_HH_MM = "YYYY-MM-DD HH:mm",
  FULL = "YYYY-MM-DD HH:mm:ss",
  WITH_TIME_ZONE = "YYYY-MM-DDTHH:mm:ss+09:00",
}

enum ViewYmdFormat {
  YYYY = "YYYY",
  MM = "MM",
  YYYY_MM = "YYYY.MM",
  YYYY_MM_DD = "YYYY.MM.DD",
  HH_MM = "HH:mm",
  TIME = "HH:mm:ss",
  YYYY_MM_DD_HH_MM = "YYYY.MM.DD HH:mm",
  FULL = "YYYY.MM.DD HH:mm:ss",
  YYYY_MM_DD_WEEK = "YYYY.MM.DD (ddd)",
  YYYY_MM_DD_WEEK_HH_MM = "YYYY.MM.DD(ddd) HH:mm",
}

/**
 * table header date view Rule
 * 대부분 목록에서만 사용
 * @param date
 * @returns { showDate = 현재 보여줘야하는 포멧 , fullDate = mouseHoverDate}
 */
const dateFormattingOperation = (date: string) => {
  //기본 포멧은 년월일
  let formatting = ViewYmdFormat.YYYY_MM_DD;
  let returnValue = { showDate: "", fullDate: "" };
  if (date) {
    //오늘 날짜
    const today = moment().format(ViewYmdFormat.YYYY_MM_DD);
    //비교날짜
    const compareDate = moment(date).format(ViewYmdFormat.YYYY_MM_DD);
    //오늘과 같은가
    if (moment(compareDate).isSame(today)) {
      //같으면 시간 포멧으로
      formatting = ViewYmdFormat.TIME;
    }
    returnValue.showDate = moment(date).format(formatting);
    //마우스오버용 년월일 시분초 포멧
    returnValue.fullDate = moment(date).format(ViewYmdFormat.FULL);
  }
  return returnValue;
};

// 전화번호 구성 변경 formatter
const PhoneFomatter = (number: string = "", type?: boolean) => {
  let formatNum = "";
  const num = number || "";

  if (num.length > 11) {
    if (num.indexOf("+82") === 0) {
      formatNum = num.replace(/(\d{4})(\d{4})(\d{4})/, "$1-$2-$3");
    }
  } else if (num.length === 11) {
    if (type) {
      formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-****-$3");
    } else {
      formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
  } else if (num.length === 8) {
    formatNum = num.replace(/(\d{4})(\d{4})/, "$1-$2");
  } else {
    if (num.indexOf("02") === 0) {
      if (type) {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, "$1-****-$3");
      } else {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
      }
    } else {
      if (type) {
        formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, "$1-***-$3");
      } else {
        formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
      }
    }
  }
  return formatNum;
};

// 비용 단위 천 / 억 으로 변환
const getKoreanCost = (number: number) => {
  const koreanUnits = ["원", "만원", "억", "조"];
  let answer = "";
  let unit = 10000;
  let index = 0;
  let division = Math.pow(unit, index);

  while (Math.floor(number / division) > 0) {
    const mod = Math.floor((number % (division * unit)) / division);
    if (mod) {
      const modToString = mod.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      answer = `${modToString}${koreanUnits[index]} ` + answer;
    }
    // division = Math.pow(unit, ++index);
    division = Math.pow(unit, ++index);
  }
  return `${answer}`;
};

// 숫자만 입력
const onlyNumber = (str: any) => {
  return str.replace(/[^0-9.]/g, "").replace(/(\.*)\./g, "$1");
};

// 숫자 / 특수기호 입력
const onlyNumberAndSign = (str: any) => {
  return str.replace(/[^0-9\-\+]/g, "").replace(/(\.*)\./g, "$1");
};

// 세자리수 콤마 찍기
const inputPriceFormat = (str: any) => {
  const comma = (str: any) => {
    str = String(str);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
  };
  const uncomma = (str: any) => {
    str = String(str);
    return str.replace(/[^\d]+/g, "");
  };
  return comma(uncomma(str));
};

// 콤마 삭제
const deleteCommas = (str: any) => {
  if (str) {
    if (typeof str === "number") {
      return str;
    } else {
      if (str !== "") {
        return str.replace(/,/g, "");
      } else {
        return;
      }
    }
  } else {
    return;
  }
  // return str.replace(/,/g, "");
};

/**
 * 휴대폰 번호 validation ("+821012345678" || "01012345678")
 * @param mobileNumber 입력한 휴대폰 번호
 * @param requiredMessage 필수입력 에러 메세지 (없으면 "필수입력항목입니다")
 * @param formatMessage 휴대폰 번호 포맷 에러 메세지 (없으면 "휴대폰 번호 형식에 맞게 입력해주세요")
 * @returns
 */
const validateMobileNumber = (mobileNumber?: string, requiredMessage?: string, formatMessage?: string): boolean | string => {
  if (!mobileNumber) return requiredMessage || "필수 입력 항목입니다";
  // const patterns = [/^010\d{8}$/, /^[+]82\d{10}$/];
  const patterns = [/^010[-]?\d{4}[-]?\d{4}$/, /^[+]82[-]?\d{2}[-]?\d{4}[-]?\d{4}$/];
  const first = patterns[0].test(mobileNumber);
  const second = patterns[1].test(mobileNumber);
  return first || second || formatMessage || "휴대폰 번호 형식에 맞게 입력해주세요";
};

/**
 * 전화 번호 validation ("+821012345678" || "01012345678")
 * @param phoneNumber 입력한 휴대폰 번호
 * @param requiredMessage 필수입력 에러 메세지 (없으면 "필수입력항목입니다")
 * @param formatMessage 휴대폰 번호 포맷 에러 메세지 (없으면 "휴대폰 번호 형식에 맞게 입력해주세요")
 * @returns
 */

// 전화번호
const validatePhoneNumber = (phoneNumber?: string, requiredMessage?: string, formatMessage?: string): boolean | string => {
  if (!phoneNumber) return requiredMessage || "필수 입력 항목입니다";
  // const patterns = [/^[+]82\d{10}$/, /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})[0-9]{3,4}[0-9]{4}$/];
  const patterns = [
    /^[+]82[-]?\d{1,2}[-]?\d{3,4}[-]?\d{4}$/, // 국제 전화번호 형식 (+82-2-1234-5678, +82-31-123-4567, etc.)
    /^(0[2-9]{1}[0-9]{0,1})[-]?\d{3,4}[-]?\d{4}$/, // 국내 전화번호 형식 (02-123-4567, 031-123-4567, etc.)
  ];
  const first = patterns[0].test(phoneNumber);
  const second = patterns[1].test(phoneNumber);

  return first || second || formatMessage || "전화번호 형식에 맞게 입력해주세요";
};

// 전화번호
const validatePhoneNumberReturnBoolean = (phoneNumber: string): boolean => {
  const patterns = [/^[+]82\d{10}$/, /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})[0-9]{3,4}[0-9]{4}$/];
  const first = patterns[0].test(phoneNumber);
  const second = patterns[1].test(phoneNumber);

  return first || second;
};

/**
 * 차량 번호 validation ("12가1234" || "123가1234")
 * @param carNumber 입력한 차량번호
 * @param isRequired 필수입력 여부 (필수입력 아닐 경우 값을 입력했을 때만 포맷 validation 체크)
 * @param requiredMessage 필수입력 에러 메세지 (없으면 "필수입력항목입니다")
 * @param formatMessage 차량번호 포맷 에러 메세지 (없으면 "차량 번호 형식에 맞게 입력해주세요")
 * @returns
 */
const validateCarNumber = (carNumber?: string, isRequired?: boolean, requiredMessage?: string, formatMessage?: string): boolean | string => {
  if (isRequired) {
    if (!carNumber) return requiredMessage || "필수입력항목입니다";
  }
  if (carNumber) {
    const pattern = /^\d{2,3}[가-힣]{1}\d{4}$/;
    return pattern.test(carNumber) || formatMessage || "차량 번호 형식에 맞게 입력해주세요";
  }
  return true;
};

// 모달에서 page 이동 시 scroll은 최상단으로 이동
// ref 타입을 HTMLElement 로 했는데 current를 잡지 못해 any로 놔두었습니다
// 사용법 - scroll을 상단으로 올릴 DOM element 를 useRef 로 잡아서 인자로 전달
const onMoveScrollTop = (element: any) => {
  element.current.scrollTo(0, 0);
};

// 분 -> 시간 변환
const showHourMinute = (onlyMinutes: string) => {
  let rtn = "";
  let hour = "";
  let minute: string | number = "";
  if (onlyMinutes) {
    if (Number(onlyMinutes) === -1) {
      hour = "제한없음";
    } else {
      if (Number(onlyMinutes) > 0) {
        hour = numberToStringWithComma(Number(Math.floor(Number(onlyMinutes) / 60).toFixed(0))) + "시간 ";
        minute = Number(Math.floor(Number(onlyMinutes) % 60).toFixed(0));
        if (minute > 0) {
          hour = hour + minute + "분";
        }
      }
    }

    rtn = hour;
  }

  return rtn;
};

const validateEmail = (email: string) => {
  const pattern = /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/g;
  return pattern.test(email);
};

const validateEmailText = (email?: string, requiredMessage?: string, formatMessage?: string): boolean | string => {
  if (!email) return requiredMessage || "필수 입력 항목입니다";
  const pattern = /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/g;
  return pattern.test(email) || formatMessage || "이메일 형식에 맞게 입력해주세요";
};

const findPgCode = (pgcode: string) => {
  let rtn = "";
  switch (pgcode) {
    case "creditcard":
      rtn = "신용카드";
      break;
    case "banktransfer":
      rtn = "계좌이체";
      break;
    case "cms":
      rtn = "CMS";
      break;
    case "virtualaccount":
      rtn = "현금(가상걔좌)";
      break;
  }
  return rtn;
};

// 법인 등록번호 중간에 하이픈 처리
const corpHypen = (str: string) => {
  let tmp = "";

  // 모든 숫자와 하이픈을 제외한 문자를 제거합니다.
  str = str.replace(/[^0-9-]/g, "");

  // 하이픈을 제거하고 숫자만으로 이루어진 문자열을 생성합니다.
  const numberOnly = str.replace(/-/g, "");

  if (numberOnly.length <= 6) {
    return numberOnly;
  } else if (numberOnly.length < 14) {
    tmp += numberOnly.substring(0, 6) + "-";
    tmp += numberOnly.substring(6);
    return tmp;
  }
};

const validatePopBillGovCode = (taxInvoiceNumber: string) => {
  const pattern = /^[a-zA-Z0-9]{8}[-\s]?[a-zA-Z0-9]{8}[-\s]?[a-zA-Z0-9]{8}$/;
  return pattern.test(taxInvoiceNumber);
};

// 사업자 등록번호 중간에 하이픈 처리
const companyRegistrationHypen = (str: string) => {
  let tmp = "";

  str = str.replace(/[^0-9]/g, "");

  if (str.length < 4) {
    return str;
  } else if (str.length < 6) {
    tmp += str.substring(0, 3);
    tmp += "-";
    tmp += str.substring(3, 5);
    return tmp;
  } else if (str.length < 11) {
    tmp += str.substring(0, 3);
    tmp += "-";
    tmp += str.substring(3, 5);
    tmp += "-";
    tmp += str.substring(5);
    return tmp;
  }
};

// 법인 등록번호 중간에 하이픈 처리
const autoHypenCorp = (str: string) => {
  let tmp = "";

  str = str.replace(/[^0-9]/g, "");

  if (str.length < 6) {
    return str;
  } else if (str.length < 14) {
    tmp += str.substring(0, 6);
    tmp += "-";
    tmp += str.substring(6);
    return tmp;
  }
};

// 문자열에서 모든 하이픈 제거.
const removeHyphens = (str: string) => {
  return str.replace(/-/g, "");
};

// date(날짜) 를 moment를 이용해 요일로 변경
const onChangeKoreanDays = (date?: string) => {
  if (date) {
    const day = moment(date).format("dddd");
    if (day) {
      let koreanDay = "";
      switch (day) {
        case "월요일":
          koreanDay = "월";
          break;
        case "화요일":
          koreanDay = "화";
          break;
        case "수요일":
          koreanDay = "수";
          break;
        case "목요일":
          koreanDay = "목";
          break;
        case "금요일":
          koreanDay = "금";
          break;
        case "토요일":
          koreanDay = "토";
          break;
        case "일요일":
          koreanDay = "일";
          break;
      }
      return koreanDay;
    }
  }
};

const changeListSearchPhoneNumber = (str: string) => {
  let rtnString = str;
  const patterns = [/^[+]82\d{10}$/, /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})[0-9]{3,4}[0-9]{4}$/, /^820\d{10}$/, /^[+]820\d{10}$/];
  //1. -제거
  rtnString = rtnString.replaceAll("-", "");
  //2. trim 처리
  rtnString = rtnString.replaceAll(" ", "");
  //3. (전화번호 형식이면)첫자리 0이면 +82로 치환
  if (patterns[1].test(rtnString)) {
    rtnString = "+82" + rtnString.replace("0", "");
  }
  if (patterns[2].test(rtnString)) {
    rtnString = rtnString.replace("820", "+82");
  }
  if (patterns[3].test(rtnString)) {
    rtnString = rtnString.replace("+820", "+82");
  }
  return rtnString;
};

// 핸드폰번호 +82 E.164 포맷으로 변경
const parsedPhoneNumber = (phone: string) => {
  let phoneNumber = "";
  if (phone) {
    if (isValidPhoneNumber(phone, "KR") === false) {
      return phone;
    }
    phoneNumber = parsePhoneNumber(phone, "KR").number;
  }
  return phoneNumber;
};

// E.164 포맷 -> 일반 핸드폰 포맷으로 변경 ex)010-1111-2222
const formatPhoneNumber = (phone: string) => {
  let phoneNumber = "";
  if (phone) {
    if (isValidPhoneNumber(phone, "KR") === false) {
      return phone;
    }
    phoneNumber = parsePhoneNumber(phone, "KR").formatNational();
  }
  // console.log("phone", phone);
  return phoneNumber;
};

export const isPhoneNumber = (phone: string, countryCode: CountryCode = "KR") => {
  return isValidPhoneNumber(phone, countryCode);
};

function getUserDevice() {
  const varUA = navigator.userAgent.toLowerCase(); // userAgent 값 얻기
  if (varUA.indexOf("android") > -1) {
    // 안드로이드
    return "android";
  } else if (varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 || varUA.indexOf("ipod") > -1 || varUA.indexOf("ios") > -1) {
    // IOS
    return "ios";
  } else {
    // IOS, 안드로이드 외 (PC)
    return "other";
  }
}

// 휴대폰번호 끝자리 4자 추출
const getFourPhoneNumber = (phone?: string) => {
  if (phone) {
    return phone.slice(-4);
  } else return "-";
};

// 숫자 3자리마다 컴마 붙이기
const changeCurrency = (value: string) => {
  let result = "";
  let count = 0;
  for (let i = value.length - 1; i >= 0; i -= 1) {
    count += 1;
    result = value[i] + result;
    if (count % 3 === 0 && i !== 0) {
      result = "," + result;
    }
  }
  return result;
};

const workSheetNoWithHyphen = (workOrderSheetNo: string) => {
  let sheetNo = workOrderSheetNo.slice(0, -2);
  sheetNo = [sheetNo.substring(0, 8), "-", sheetNo.substring(8)].join("");
  return sheetNo;
};

const isPublicPage = (pathname: string) => {
  const publicURL = "/mng/ctrl/public";
  return pathname.includes(publicURL);
};

export const isDevEnv = () => {
  return process.env.REACT_APP_ENVIRONMENT === "dev";
};

// pagePath return
export const ctrlRoomPath = (path: string, id?: string) => {
  const partnerId = localStorage.getItem("partnerId");
  const returnPath = `${path.replace(":partnerId", String(id ? id : partnerId))}`;
  return returnPath;
};

const columnHeader: any = [
  {
    Header: "일시",
    accessor: "datetime",
    // sticky: "left",
    width: 220,
    Cell: ({ value }: any) => {},
  },
];

// 엑셀 다운로드
const downloadExcel = async <T>({ data, fileName, header }: { data: T[]; fileName: string; header: string[] }) => {
  const XLSX = await import("xlsx");

  // 데이터랑 컬럼을 받아서
  // 컬럼에 accessor 를 뽑아서 데이터랑 키값 맞춰서 순서대로 만들어줘

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
  XLSX.utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `${fileName}.xlsx`);

  return true;
};

export const downloadExcelV2 = async <
  A extends string,
  T extends Record<A, any>, // T는 A의 키를 가지는 객체여야 함
>({
  data,
  fileName,
  columns,
}: {
  data: T[];
  fileName: string;
  columns: {
    Header: string;
    accessor: A; // accessor는 A의 값 중 하나여야 함
    // [key: string]: any;
  }[];
}) => {
  const XLSX = await import("xlsx");

  // 헤더 추출
  const header = columns.map((col) => col.Header);

  // accessor 배열을 Set으로 변환하여 빠른 존재 여부 체크
  const accessorsSet = new Set(columns.map((col) => col.accessor));

  // 데이터 변환 및 accessor 체크
  const formattedData = data.map((item) => {
    // item의 키를 Set으로 변환
    const itemKeysSet = new Set(Object.keys(item));

    // 누락된 accessor를 체크
    const missingAccessors = [...accessorsSet].filter((accessor) => !itemKeysSet.has(accessor as string));

    if (missingAccessors.length > 0) {
      console.error(`Missing accessors: ${missingAccessors.join(", ")}`);
      // throw new Error(`Missing accessors: ${missingAccessors.join(", ")}`);
    }

    return columns.reduce((acc, col) => {
      acc[col.Header] = item[col.accessor];
      return acc;
    }, {} as Record<string, any>);
  });

  console.log("formattedData :>> ", formattedData);

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
  XLSX.utils.sheet_add_json(ws, formattedData, { origin: "A2", skipHeader: true });
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `${fileName}.xlsx`);

  return true;
};

export const createExcelFile = async <A extends string, T extends Record<A, any>>({
  data,
  fileName,
  columns,
}: {
  data: T[];
  fileName: string;
  columns: {
    Header: string;
    accessor: A;
  }[];
}): Promise<File> => {
  const XLSX = await import("xlsx");
  // 헤더 추출
  const header = columns.map((col) => col.Header);

  // 데이터 변환
  const formattedData = data.map((item) => {
    return columns.reduce((acc, col) => {
      acc[col.Header] = item[col.accessor];
      return acc;
    }, {} as Record<string, any>);
  });

  // 워크북 생성
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet([]);

  // 데이터 추가
  XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
  XLSX.utils.sheet_add_json(ws, formattedData, { origin: "A2", skipHeader: true });
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // 워크북을 바이너리 데이터로 변환
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  // File 객체 생성 및 반환
  return new File([excelBuffer], `${fileName}.xlsx`, {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
};

// locatoin parsing 하기
const qsParse = (locationSearch: string) => {
  return qs.parse(locationSearch, { ignoreQueryPrefix: true, allowDots: true, decoder: (value) => decodeURIComponent(value) });
};

export const calculateDate = (start: string, end: string, serviceType?: string) => {
  let diffTime = {
    day: 0,
    year: 0,
    month: 0,
    hour: 0,
    minute: 0,
  };
  let returnText = "";
  if (start && end) {
    if (serviceType === undefined || serviceType === "FULL_COURT" || serviceType === "OPEN_COURT") {
      let startTime = moment(start).format(ViewYmdFormat.YYYY_MM_DD);
      let endTime = moment(end).format(ViewYmdFormat.YYYY_MM_DD);
      diffTime = {
        day: moment.duration(moment(endTime).diff(moment(startTime))).days(),
        year: moment.duration(moment(endTime).diff(moment(startTime))).years(),
        month: moment.duration(moment(endTime).diff(moment(startTime))).months(),
        hour: 0,
        minute: 0,
      };
    } else {
      diffTime = {
        day: 0,
        year: 0,
        month: 0,
        hour: moment.duration(moment(end).diff(moment(start))).hours(),
        minute: moment.duration(moment(end).diff(moment(start))).minutes(),
      };
    }
  }
  // console.log("diffTime", diffTime);
  if (serviceType === undefined || serviceType === "FULL_COURT" || serviceType === "OPEN_COURT") {
    if (diffTime.year !== 0) {
      returnText = diffTime.year + "년";
    }

    if (diffTime.month !== 0) {
      returnText = returnText !== "" ? returnText + " " + diffTime.month + "개월" : +diffTime.month + "개월";
    }

    if (diffTime.day !== 0) {
      returnText = returnText !== "" ? returnText + " " + diffTime.day + "일" : +diffTime.day + "일";
    }
  } else {
    if (diffTime.hour !== 0) {
      returnText = returnText + diffTime.hour + "시간";
    }

    if (diffTime.minute !== 0) {
      returnText = returnText !== "" ? returnText + " " + diffTime.minute + "분" : +diffTime.minute + "분";
    }
  }

  // console.log("returnText", returnText);

  return returnText;
};

// 타입가드와 타입축소용 함수
const isString = (value: any): value is string => typeof value === "string";

/** number | string | "100,000" 형식을 인자로 받아서 "100,000" | "" 형식으로 리턴 */
const commaizePrice = (value: string | number | null | undefined) => {
  if (value === "" || value === "-1" || value === -1 || value === undefined || value === null) {
    return "";
  }

  return commaizeNumber(value.toString().replace(/[^0-9.]/g, ""));
};

const typeToString = (value: string | undefined | null | number) => {
  if (value === "" || value === "-1" || value === -1 || value === null || value === undefined) {
    return "";
  }

  return value.toString();
};

/** 분 number 입력시 "x시간" or "x시간x분" 으로 리턴해주는 함수 */
const makeTimeString = (value: number) => {
  const hour = Math.floor(value / 60);
  const minute = Math.floor(value % 60);

  if (value < 60) {
    return `${value}분`;
  }

  if (minute === 0) {
    return `${hour}시간`;
  }

  return `${hour}시간 ${minute}분`;
};

/** 객체 배열을 순회하면서 특정키의 값이 중복되는 경우 true 리턴  */
function hasDuplicateValue(arr: any[], key: string): boolean {
  const priorities = new Set();

  for (let i = 0; i < arr.length; i++) {
    const priority = arr[i][key];

    if (priorities.has(priority)) {
      return true;
    }

    priorities.add(priority);
  }

  return false;
}

function generateNumberArrayFromAtoB(A: number, B: number) {
  // A부터 B까지의 숫자로 이루어진 배열 생성
  const numberArray = Array.from({ length: B - A + 1 }, (_, index) => A + index);

  return numberArray;
}

// 소수점 반올림. 1자리까지 표기
export const roundFloat = (float?: number, digit: number = 1) => {
  const toFixedFloat = (float?: number, digit: number = 1) => {
    if (_.isNil(float)) {
      return;
    }
    return float.toFixed(digit);
  };
  if (_.isNil(float)) {
    return "";
  }
  return toFixedFloat(Math.round(float * Math.pow(10, digit)) / Math.pow(10, digit));
};

export const BULLET = "•";
// 원본 데이터 타입 정의
type UDDData = {
  id?: string;
  cmdType?: "C" | "U" | "D";
  [key: string]: any; // 추가적인 키를 허용
};

/**
 * 원본 데이터와 업데이트 데이터를 비교해서 cmdType 값 변경
 * 변경된 값이 없으면 배열에서 제거
 * @param originalData
 * @param updatedData
 * @returns
 *
 */
function compareCUDData(originalData: UDDData[], updatedData: UDDData[]): UDDData[] {
  // 새로운 데이터 생성
  const newData = updatedData
    .map((updatedItem) => {
      const originalItem = originalData?.find((item) => item.id && item.id === updatedItem.id);

      console.log("originalItem :>> ", originalItem);

      if (!originalItem) {
        // 원본 데이터에 없던 경우
        return { ...updatedItem, cmdType: "C" };
      } else {
        // 원본 데이터에서 수정된 경우
        const hasChanges = Object.keys(updatedItem).some((key) => key !== "id" && key !== "cmdType" && updatedItem[key] !== originalItem[key]);

        if (hasChanges) {
          return { ...updatedItem, cmdType: "U" };
        }
        // 원본 데이터와 동일한 경우
        return null; // 동일한 항목은 null로 반환
      }
    })
    .filter((item) => item !== null); // null 제거

  // 삭제된 항목 처리
  const deletedItems = originalData
    .filter((originalItem) => !updatedData.some((updatedItem) => updatedItem.id === originalItem.id))
    .map((deletedItem) => ({
      ...deletedItem,
      cmdType: "D",
    }));

  // 최종 데이터 결합
  return [...newData, ...deletedItems] as UDDData[];
}

function sortDays(daysString: string): string {
  // 요일의 순서를 정의
  const order: string[] = ["월", "화", "수", "목", "금", "토", "일"];

  // 입력된 문자열을 배열로 변환하고, 각 요일을 trim하여 공백 제거
  const daysArray: string[] = daysString.split(",").map((day) => day.trim());

  // 요일 순서에 따라 정렬
  daysArray.sort((a, b) => order.indexOf(a) - order.indexOf(b));

  // 정렬된 배열을 다시 문자열로 변환
  return daysArray.join(",");
}

export {
  downloadExcel,
  numberToStringWithComma,
  roundToTwo,
  floorToTwo,
  calculatePyeong,
  calculatePyeongNum,
  dateFormattingOperation,
  PhoneFomatter,
  YmdFormat,
  ViewYmdFormat,
  getKoreanCost,
  calculateRateOfUse,
  onlyNumber,
  deleteCommas,
  onlyNumberAndSign,
  inputPriceFormat,
  validateMobileNumber,
  validateCarNumber,
  onMoveScrollTop,
  showHourMinute,
  findPgCode,
  validateEmail,
  validateEmailText,
  validatePopBillGovCode,
  companyRegistrationHypen,
  corpHypen,
  removeHyphens,
  validatePhoneNumber,
  onChangeKoreanDays,
  changeListSearchPhoneNumber,
  parsedPhoneNumber,
  formatPhoneNumber,
  getUserDevice,
  getFourPhoneNumber,
  changeCurrency,
  workSheetNoWithHyphen,
  isPublicPage,
  validatePhoneNumberReturnBoolean,
  autoHypenCorp,
  qsParse,
  isString,
  commaizePrice,
  makeTimeString,
  hasDuplicateValue,
  generateNumberArrayFromAtoB,
  compareCUDData,
  typeToString,
  sortDays,
};
